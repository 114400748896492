*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body{
  overflow-x: hidden;
}

.main-header{
  background-image: url('./images/slider_image.jpg');
  /*height: 750px; */
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 20px;
}

.menu{
  width: 1200px;
  box-shadow: 5px 5px 30px rgb(202, 201, 201);
  margin: auto;
  padding-top: 20px;
}

.menu ul{
  display: flex; 
}

.menu li{
  width: 125px; 
  list-style-type: none;
}
ul.serve li{
  list-style-position: outside;
  line-height: 1.5;
}
ul.serve li {  
  list-style-type: none;
}
ul.serve li:before { 
  content:"\2611\0020"; 
  list-style-type: none;
}

.menu li a{
  display: block;
  color: black;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
}
/*
div.box:hover{
  background-color: blue;
  color: white;
}
*/

.service{
  text-align: center;
  align-items: center;
  padding-left: 100px;
}

div.service h3{
  font-size: 18px;
  font-weight: 700;
}
.contact{
  background-color: white;
}
.contact-header{
  font-size: 60px;
  font-weight: 700;
}

.contactDivs{
  box-shadow: 5px 5px 30px rgb(202, 201, 201);
}

.contactDivs input::placeholder{
  color: lightgrey;
}

.contact:hover{
  background-color: black;
  color: white;
}

.call{
  background-image: url('./images/about.jpg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.call-head{
  color: white;
}

.call-head h2{
  font-size: 60px;
  font-weight: 700;
  text-align: center;
}
.footer-main{
  color: white;
  background-image: url('./images/airplane.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
}
.footer-main-inner-div{
  background-color: rgba( 5, 12, 36,0.9);
  height: 400px; 
  padding-top: 50px;
}
.footer-main-inner-div h3{
  margin-bottom: 50px;
  color: lightblue;
  font-weight: 600;
}
.footer-main-inner-div li{
  line-height: 30px;
}
.footer-main-inner-div a{
  text-decoration: none;
  color: white;
}
.footer-main-inner-div p, .footer-main-inner-div span{
  color: lightblue;
}
.team{
  margin: 40px;
}
.team h2{
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 50px;
}
.team p{
  color: blue;
  font-weight: 600;
  text-align: center;
}
.team-name{
  color: white;
  font-weight: 700;
  font-size: 20px;
}
.boxshadow{
  box-shadow: 5px 5px 15px lightgrey;
}
.team-box{
  position: relative;
  background-image: linear-gradient(white 0%, white 80%, black 80%, black 100%); 
  background-repeat: no-repeat; 
  background-size: contain; 
  height: 420px;
  background-color: black;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(345deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%,rgba(0,0,255,0.8) 40%, rgba(0,0,255,0.8) 100%);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}
.team-box:hover .overlay {
  height: 100%;
}

.pname{
  font-size:25px;
  font-weight: 700;
  color: black;
}

.testimony-main{
  background-image: url('./images/feature-bg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.testimony-head{
  font-size: 60px;
  font-weight: 600;
  text-align: center;
}

.testimony-sub-head{
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.testimony p{
  margin: 40px 0px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
}
.testimony h2{
  font-size:   50px;
  font-weight: 700;
}

.testimony button{
  font-size:     20px;
  font-weight:   600;
  margin-bottom: 20px;
}
.testimony span{
  color: blue;
}
.box{
  margin: 10px;
  background-color: white;
}

.box:hover{
  background-color:  black;
  color: white;
  /*transition: 3s all;*/
}

.getintouch-bg{
  background-image: linear-gradient(white 0%, white 50%, black 50%, black 100%);
  padding: 50px 50px 0px 50px;
  z-index: 99; 
}

.horizontal-menu{
  display: flex;
  width: 100%;
}

.sbanner{
  background-image: url('./images/sky-plane.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid blue;
}

.sbanner-inner{
  background-color: rgba(0,0,255,0.6);
  height: 250px;
}

.getintouch-bg-inner{
 background-image: linear-gradient(50deg, rgba(0,0,255,1) 50%, white 50%);
 padding:            50px;
 margin:             50px 50px 0px 50px;
 z-index:            9;
 color:            white;
 font-weight:        bolder;
 /*border: 1px solid blue; */
}

.getintouch-bg-inner-column1{
 padding: 0;
}

.getintouch-bg-inner-column2{
 margin:  auto;
 color: blue;
}

#airplane:hover{
  rotate: -20deg;
  transition: all 3s;
}
.about{
  /* background-color: white; */
}
.about button{
  background-color: white;
  font-size: 20px;
  border-radius: 20px;
  border: none;
  padding: 5px 50px;
  margin-bottom: 30px;
}
.about h2{
  font-size: 50px;
  font-weight: 700;
}
.about h4{
  font-weight: 700;
}
.about span{
  color: blue;
}
.cont{
  background-image: url('./images/bg-design.jpg');
  background-repeat: no-repeat;
  background-size:cover;
}

.abt{
  background-image: url('./images/bg2.jpg');
  background-repeat: no-repeat;
  background-size:cover;
}

.travel{
  background-image: url('./images/girl1.png');
  background-repeat: no-repeat;
  background-size:cover;
  padding: 0px;
}
  
.travel h2{
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media screen and (max-width: 480px){
  .footer-main{
    color: white;
    background-image: url('./images/airplane.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
  }

  .footer-main-inner-div{
    background-color: rgba( 5, 12, 36,0.9);
    height: auto;
  }

  .team-box{
    position: relative;
    background-image: linear-gradient(white 0%, white 80%, black 80%, black 100%); 
    background-repeat: no-repeat; 
    background-size: contain; 
    height: auto;
    background-color: black;
  }

  .service{
    text-align: center;
    align-items: center;
    padding-left: 0px;
  }
}